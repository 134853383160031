@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.timeline {
  &__item + &__item {
    margin-top: 4em;
  }

  &__school {
    margin: .9em 0 .4em;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__time {
    position: relative;
    margin: 0;
    padding: .7em 1.5em;
    font-size: .65rem;
    font-weight: 600;
    color: rgba($action, .6);
    text-transform: uppercase;
    background-color: rgba($action, .1);
    border-radius: 4px;
    border-radius: $radius;
  }

  &__city {
    margin: 0;
    font-size: .8rem;
    font-weight: 600;
    color: #9e9e9e;

    svg {
      width: 10px;
      margin-right: 6px;
    }
  }

  &__description {
    @extend %text;

    padding-top: 2em;
    line-height: 1.5;
  }

  &__icon {
    margin-bottom: -45px;
    transform: translateX(-20%);
  }

  @media #{$screen-md} {
    position: relative;

    &__list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: -2em 0;
    }

    &__item {
      flex: 0 0 45%;
      padding: 2em 0;

      & + & {
        margin-top: 0;
      }
    }
  }
}
