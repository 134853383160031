$headerHeight: 60px;
$radius: 5px;

%text {
  font-size: .95rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba($text, .7);
}

%fullsize-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@mixin dottedList($fontSize, $dotSize, $padding) {
  position: relative;
  padding-left: $padding;
  font-size: $fontSize;

  &:after {
    position: absolute;
    top: calc(#{$fontSize} / 2);
    left: .5em;
    width: $dotSize;
    height: $dotSize;
    background-color: $action;
    border-radius: 30%;
    content: '';
    transform: rotate(45deg);
    box-shadow: 0 5px 5px rgba($action, .08);
  }
}

@mixin flex-font($minFont, $maxFont) {
  font-size: calc(#{$minFont}px + (#{$maxFont} - #{$minFont}) * ((100vw - 600px) / (1440 - 600)));
}
