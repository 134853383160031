@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.referencesPreview {
  $_spacing: 1em;

  padding-bottom: 4em;

  &__header {
    position: relative;
    overflow: hidden;
    background: $actionGradinet;
  }

  &__headerContainer {
    @extend %container;

    position: relative;
    padding: 7em 0;
    color: $white;
    text-align: center;
  }

  &__hint {
    margin: 0 auto;
    font-size: .8rem;
    text-transform: uppercase;
  }

  &__section {
    @extend %container;

    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    max-width: 50rem;
  }

  &__title {
    text-align: center;
    margin: 1em auto .3em;
  }

  &__image {
    width: 100%;
  }

  &__imageWrapper {
    width: 9.5rem;
    height: 9.5rem;
    margin: calc(-9.5rem / 2) auto 0;
    overflow: hidden;
    background-color: $background;
    border: 5px solid $white;
    border-radius: 100%;
    box-shadow: 0 3px 20px 0 rgba(43, 47, 53, 0.1);
  }

  &__content {
    @extend %container;

    max-width: 800px;
    margin-top: 3em;
  }

  &__text {
    @extend %text;

    margin-top: 1em;
    text-align: left;

    &:first-of-type {
      margin-top: 2em;
    }

    a {
      color: $action;
      text-decoration: none;
    }
  }
}
