@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.heading {
  @include flex-font(40, 50);

  margin: 0;
  margin-bottom: .8em;
  color: #3a363c;

  &--white {
    color: $white;
  }

  &--centered {
    text-align: center;
  }

  @media #{$screen-md} {
    &--spaced {
      margin-bottom: 100px;
    }
  }
}
