@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.depiction {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  padding: 4em 0;
  text-align: center;

  & + & {
    border-top: 1px solid rgba($mercury, .5);
  }

  &:first-child {
    margin-top: -140px;
  }

  &__row {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin: 2em 0 1em;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #3a363c;
  }

  &__text {
    @extend %text;
  }

  &__action {
    margin-top: 3em;
  }

  &__invisibleLink:hover .depiction__project {
    transform: scale(1.05);
  }

  &__project {
    transition: transform .3s ease;
  }

  &__shape {
    display: none;
  }

  @media #{$screen-md} {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      margin-top: -180px;
    }

    &__row {
      align-items: flex-start;
      flex: 0 0 45%;
    }

    &--reversed {
      flex-direction: row-reverse;
    }
  }

  @media #{$screen-lg} {
    &:first-child {
      margin-top: -280px;
    }
  }
}
