@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.tabs {
  $spacing: 6em;

  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 3px 20px 0 rgba(43, 47, 53, .1);

  &__content,
  &__navbar {
    flex: 0 0 100%;
  }

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__article {
    padding: 0 2em 2em;
  }

  &__navbar {
    position: relative;
    padding: 0;
    font-size: .8rem;
    font-weight: 400;
    background-color: rgba($background, .6);
    border-bottom: 1px solid $background;
  }

  &__heading {
    display: inline-block;
    font-size: .7rem;
    font-weight: 600;
    margin: 0 0 .5em;
    margin-top: .7em;
  }

  &__heading + &__heading {
    margin-left: .8em;
  }

  &__list {
    padding: 0;
  }

  &__tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 25%;
    overflow: hidden;
    padding: 2em 1em;
    text-align: left;
    border: 1px solid $white;
    border-radius: 4px;
    color: #9e9e9e;
    cursor: pointer;

    &--active {
      background: $actionGradinet;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    margin-top: 0;
    color: $text;
    text-align: center;
  }

  &__title {
    margin: 0;
  }

  &__company {
    margin-top: .5em;
    margin-bottom: 0;
    font-size: 12px;
    color: $pale;
  }

  &__logoWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background-color: $white;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 3px 60px 0 rgba($action, .06);
  }

  &__previewWrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 2.18rem;
    height: 2.18rem;
    margin-right: 1em;
    background-color: $white;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 3px 20px 0 rgba(43, 47, 53, .1);
  }

  &__logo {
    width: 70%;
  }

  &__time {
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: .7em 1.5em;
    font-size: .65rem;
    font-weight: 600;
    color: rgba($action, .6);
    text-transform: uppercase;
    background-color: rgba($action, .1);
    border-radius: 4px;
    border-radius: $radius;
  }

  &__article {
    @extend %text;

    font-size: .9rem;

    p + p,
    p + ul,
    ul + ul,
    ul + p {
      margin-top: 1em;
    }

    li {
      @include dottedList(.9rem, 4px, 2em);
    }

    li + li {
      margin-top: .5em;
    }

    & + & {
      margin-top: 2em;
    }
  }

  @media #{$screen-md} {
    margin-top: 6em;
    flex-direction: row;

    &__navbar {
      max-width: 20em;
      padding: 0;
      border-bottom: 0px;

      &:before {
        content: none;
      }
    }

    &__content {
      flex: 0 1 calc(100% - 20em);
    }

    &__article {
      padding: 2em;
      border-right: 1px solid $background;
      min-height: 25rem;
    }
  }

  @media #{$screen-lg} {
    &__article {
      padding: 2em 4em;
      border-left: 1px solid $background;
    }
  }
}
