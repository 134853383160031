@import '../style/base/colors';
@import '../style/base/layout';

.carousel {
  position: relative;
  width: 100%;
  margin-top: 4em;

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__list {
    display: flex;
  }

  &__item {
    flex: 1 0 100%;
    width: auto;
  }

  &__dots {
    display: flex;
    justify-content: center;
    padding-top: 2em;
  }

  &__dot {
    position: relative;
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-color: $action;
    border: 0;
    border-radius: 100%;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 100%;
      content: '';
      transform: scale(0);
      transition: transform .3s ease-in-out;
    }

    &--active:before {
      transform: scale(1);
    }
  }

  &__dot + &__dot {
    margin-left: 10px;
  }

  &__button {
    position: absolute;
    bottom: -15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 100%;
    transition: transform .3s, opacity .3s, background .3s;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 60%;
      height: 4px;
      background-color: $white;
      border-radius: 4px;
      content: '';
      transition: transform .3s ease, width .3s ease .5s, background-color .3s;
    }

    &:before {
      top: calc((100% + 3px) / 2);
    }

    &:after {
      top: calc((50% - 3px) / 2);
    }

    &:hover {
      &:before,
      &:after {
        background-color: $white;
      }
    }

    &:active {
      transform: translateY(3px);
    }

    &:disabled {
      &:after,
      &:before {
        top: calc((100% - 4px) / 2);
        width: 0;
        transform: rotate(0);
      }
    }

    &--left {
      left: 0;

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &--right {
      right: 0;

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }

  @media #{$screen-md} {
    margin-top: 6em;
    padding: 0 50px;

    &__item {
      padding: 0 .7em;
    }

    &__button {
      top: calc(40% - 15px);
      bottom: unset;
    }

    &__dots {
      margin-top: 2em;
    }
  }

  @media only screen and (min-width: 56.36rem) {
    &__item {
      flex: 1 0 50%;
    }
  }
}
