@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.flexList {
  $_screen_md: 'only screen and (min-width: 500px)';

  position: relative;
  margin-bottom: -30px;
  padding: 3em 0;
  background-color: $background;

  &__content {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar-track {
      margin: 0 $container-gutter;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      height: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 15px;
    }
  }

  &__heading {
    font-size: .8rem;
    color: $pale;
    text-transform: uppercase;
  }

  &__heading,
  &__container {
    @extend %container;
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 20px 0;
  }

  &__item {
    left: 0;
    flex: 0 0 270px;
    padding-right: calc($container-gutter / 2);
  }
}
