@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.productPreview {
  $_spacing: 1em;

  padding-bottom: 4em;

  &__header {
    position: relative;
    overflow: hidden;
    background: $actionGradinet;
  }

  &__headerContainer {
    @extend %container;

    position: relative;
    padding: 8em $container-gutter;
    color: $white;
    text-align: center;
  }

  &__hint {
    font-size: .8rem;
    text-transform: uppercase;
  }

  &__tile {
    @extend %container;

    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    width: 10em;
    height: 10em;
    margin-top: -5em;
    overflow: hidden;
    background-color: $white;
    border-radius: 100%;
    border: 5px solid $white;
    box-shadow: 0 3px 20px 0 rgba(43, 47, 53, .1);
  }

  &__preview {
    position: absolute;
    width: 120%;
    max-width: 120%;
    min-height: 100%;
    object-fit: cover;
  }

  &__subtitle {
    width: 100%;
    padding: 1em 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: rgba($text, .6);
  }

  &__image {
    width: 100%;
  }

  &__imageWrapper {
    width: calc(100% + (2 * #{$container-gutter}));
    margin: 0 (-$container-gutter);
    margin-top: 0;

    &--sm {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__caption {
    padding: 0 1em 1em;
    margin: .5em 0;
    font-size: .9rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.4;
    color: rgba($text, .7);
    border-bottom: 1px solid rgba($text, .1);
  }

  &__content {
    @extend %container;

    max-width: 50rem;
    margin-top: 3em;
  }

  &__text {
    @extend %text;
  }

  &__text + &__text,
  &__text + &__imageWrapper,
  &__text + &__movie,
  &__imageWrapper + &__text,
  &__imageWrapper + &__movie,
  &__movie + &__text,
  &__movie + &__imageWrapper {
    margin-top: $_spacing;
  }
  
  &__imageWrapper + &__imageWrapper {
    margin-top: $_spacing * 2;
  }

  a {
    color: $action;
    text-decoration: none;
  }

  @media #{$screen-md} {
    &__imageWrapper {
      width: 100%;
      margin: 0;

      &--sm {
        width: 60%;
        margin: 0 auto;
      }
    }

    &__caption {
      border-left: 1px solid rgba($text, .1);
      border-bottom-left-radius: 4px;
    }
  }
}
