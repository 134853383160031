$white: white;
$text: #3a363c;
$background: #f7f8fa;
$action: #8b0007;

$rose: #e13852;
$pale: #cfcbce;
$mercury: #e5e4e4;

$actionGradinet: linear-gradient(to right, $action 0%, $rose 100%);
$lightGradinet: linear-gradient(60deg, $white 0%, #fadcdc 160%);
