@import '../style/base/colors';
@import '../style/base/layout';
@import '../style/base/variables';

.footer {
  position: relative;
  overflow: hidden;
  color: $white;
  text-align: center;
  background: $actionGradinet;

  &__container {
    @extend %container;
  }

  &__list {
    position: relative;
    z-index: 2;
    padding: 50px 0;
  }

  &__item {
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: 2em;
    }
  }

  &__heading {
    margin: 0 0 1em;
  }

  &__text,
  &__link {
    font-size: .9rem;
    line-height: 1.6;
    color: $white;
    text-decoration: none;
  }

  &__link {
    align-self: center;
    position: relative;
    display: block;

    &:before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      content: '';
      background: $white;
      transform: scale(0);
      transition: transform .3s ease-in-out;
    }

    &:hover:before {
      transform: scale(1);
    }
  }

  &__copyright {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 1rem;
    opacity: .6;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, rgba($white, .2) 0%, transparent 110%);
      content: '';
    }
  }

  &__bgShape {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: 700px;
    transform: translate(15%, -35%) rotate(190deg);
    user-select: none;
  }

  &__icons {
    display: flex;
    justify-content: center;
  }

  &__icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    overflow: hidden;
    background: $white;
    border-radius: 3px;
    transition: opacity .2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    & + & {
      margin-left: .8rem;
    }

  }

  @media #{$screen-sm} {
    &__list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &__item {
      flex: 0 0 calc(100% / 2);

      & + & {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 3em;
      }
    }
  }

  @media #{$screen-md} {
    text-align: left;

    &__list {
      justify-content: space-between;
    }

    &__item {
      flex: 0 0 calc(100% / 3);

      & + & {
        padding-left: 1em;
      }

      &:last-child {
        margin-top: 0;
      }
    }

    &__icons {
      justify-content: left;
    }

    &__link {
      align-self: flex-start;
    }
  }
}
