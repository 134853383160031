@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.card {
  $_screen_md: 'only screen and (min-width: 500px)';

  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(187, 173, 173, .1);
  transition: box-shadow .2s ease-in-out;

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  &__header {
    position: relative;
    width: 100%;
    background-color: $mercury;
  }

  &__imageWrapper {
    position: relative;
    padding-top: 60%;
    overflow: hidden;
  }

  &__image {
    @extend %fullsize-centered;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1em;
    background-color: $white;
    border: 1px solid $background;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__hint {
    position: relative;
    margin: 0;
    padding: .7em 1.5em;
    font-size: .65rem;
    color: rgba($action, .6);
    text-transform: uppercase;
    background-color: rgba($action, .1);
    border-radius: 4px;
    border-radius: $radius;
  }

  &__title {
    position: relative;
    height: calc(.9rem * 2 * 1.3) ;
    margin: 0;
    margin-top: 1em;
    overflow: hidden;
    font-size: .9rem;
    line-height: 1.3;
  }

  &:hover {
    box-shadow: 0 3px 20px 0 rgba(43, 47, 53, .1);
  }

  @media #{$screen-md} {
    &__content {
      padding: 1.5em;
    }
  }
}
