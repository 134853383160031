@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.navbar {
  $spacing: 2em;
  $burgerBarSize: 3px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: $headerHeight;
  background-color: transparent;

  &,
  &__container {
    display: flex;
    align-items: flex-end;
  }

  &__container {
    @extend %container;

    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &:before {
      position: absolute;
      bottom: -10px;
      left: $container-gutter;
      width: calc(100% - #{$container-gutter});
      height: 1px;
      background: linear-gradient(to right, rgba($white, .2) 0%, transparent 80%);
      content: '';
    }
  }

  &__link {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2.5px;
    color: $white;
    text-decoration: none;
    opacity: .8;
  }

  &__logo {
    height: 20px;
  }

  &__list {
    display: flex;
    margin: 0;
  }

  &__item {
    position: relative;
    padding-top: 2px;
    font-size: .8rem;
    font-weight: 600;
    line-height: 1;
    color: rgba($text, .6);
    text-transform: uppercase;
    cursor: pointer;
    transition: color .3s ease-in-out;

    &:hover {
      color: $text;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 5px;
      height: 100%;
      font-size: inherit;
      font-weight: 600;
      color: $pale;
      transform: scale(0);
      transition: transform .3s ease-in-out;
    }

    &:before {
      left: -10px;
      content: '{';
    }

    &:after {
      right: -10px;
      content: '}';
    }

    &:active,
    &--active {
      color: $action;

      &:before,
      &:after {
        transform: scale(1);
      }
    }
  }

  &__item + &__item {
    margin-top: $spacing;
  }

  &__burger {
    display: none;
  }

  &__burgerPlaceholder {
    position: relative;
    width: 25px;
    height: 20px;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 100%;
      height: $burgerBarSize;
      background-color: $action;
      content: '';
      transition: transform .3s ease-in-out;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &__burgerIcon {
    position: absolute;
    top: calc((100% - #{$burgerBarSize}) / 2);
    width: 100%;
    height: #{$burgerBarSize};
    background-color: $action;
  }

  &__burger:checked ~ &__burgerPlaceholder {
    &:before {
      transform: translateY(9px);
    }

    &:after {
      transform: translateY(-9px);
      box-shadow: none;
    }
  }

  @media #{$screen-max-md} {
    &__list {
      position: fixed;
      top: $headerHeight;
      right: -100%;
      z-index: -1;
      align-items: flex-end;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: ($spacing * 2) $spacing;
      overflow: scroll;
      font-size: 1.3em;
      background-color: $mercury;
      transition: transform .5s;
    }

    &__burger:checked ~ &__list {
      transform: translateX(-100%);
    }
  }

  @media #{$screen-md} {
    &__list {
      position: relative;
      width: auto;
      height: auto;
    }

    &__item + &__item {
      margin-top: 0;
      margin-left: $spacing;
    }

    &__burgerIcon,
    &__burgerPlaceholder {
      display: none;
    }
  }
}
