@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  color: $text;
  background-color: $white;
  border-radius: 4px;

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em 1.5em;
    background: linear-gradient(to right, #f2f2f247 0, #fcfcfc5e 150%);
    border-bottom: 1px solid $background;
  }

  &__imageWrapper {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    background: $pale;
    border-radius: 100%;
    border: 3px solid $white;
    box-shadow: 0 0 40px 0 #d5d5d5;
  }

  &__image {
    @extend %fullsize-centered;
  }

  &__heading {
    margin-top: 1em;
    text-align: center;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5em;
  }

  &__title {
    margin: 0;
    margin-bottom: .3em;
    font-size: .9rem;
    color: #3a363c;
    text-decoration: none;
  }

  &__subtitle {
    margin: 0;
    font-size: .9rem;
    font-weight: 600;
    color: darken($pale, 4%);

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__company {
    margin: 0;
    font-size: .7rem;
    font-weight: 600;
    letter-spacing: .5px;
    color: $action;

    svg {
      width: 14px;
      margin-right: 6px;
      margin-bottom: -2px;
    }
  }

  &__text {
    @extend %text;

    position: relative;
    height: calc(.9rem * 1.5 * 4);
    margin-bottom: 1.5em;
    overflow: hidden;
    font-size: .9rem;
    font-weight: 400;
    text-align: center;
  }

  &__action {
    display: block;
    margin-top: auto;
  }

  @media #{$screen-md} {
    &__header {
      flex-direction: row;
    }

    &__heading {
      margin-top: 0;
      margin-left: 2em;
      text-align: left;
    }

    &__content {
      align-items: flex-start;
    }

    &__text {
      text-align: left;
    }
  }
}
