@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.description {
  display: flex;
  flex-direction: column;

  &__content,
  &__list {
    flex: 0 0 100%;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__list {
    margin-top: 2em;
    padding: 0;
  }

  &__text {
    @extend %text;
    margin-top: 1em;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(50%, 0%);
  }

  &__item {
    @include dottedList(.95rem, 6px, 2.5em);
    @extend %text;

    & + & {
      margin-top: 1.8em;
    }
  }

  @media #{$screen-md} {
    flex-direction: row;
    justify-content: space-between;

    &__content,
    &__list {
      flex: 0 0 45%;
      max-width: auto;
      margin-top: 0;
    }

    &__content {
      position: relative;
      margin-top: 5em;
    }

    &__icon {
      left: 0;
      transform: translate(-20%, -80%);
    }
  }
}
