@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.section {
  $padding: 120px;

  position: relative;
  margin-top: -1px;
  overflow: hidden;
  background-color: $white;

  &__container {
    @extend %container;

    position: relative;
    z-index: 2;
  }

  &--spaced .section__container {
    padding-top: calc($padding / 2);
    padding-bottom: calc($padding / 2);
  }

  &--dark {
    background-color: $background;
    border-bottom: 1px solid rgba($mercury, .5);
  }

  &--color {
    color: $white;
    background: $actionGradinet;
  }

  &--shapedTop {
    padding-top: 100px;

    .section__shape {
      position: absolute;
      top: -2px;
      left: calc((100% - 140%) / 2);
      z-index: 0;
      width: 140%;
      height: 100px;
      overflow: hidden;
      transform: rotate(180deg);

      &:before {
        position: absolute;
        bottom: -336px;
        left: 0;
        width: 100%;
        height: 400px;
        background-color: $white;
        content: '';
        transform: rotate(-3deg);
      }
    }
  }

  &--shapedBottom {
    padding-bottom: 100px;

    .section__shape {
      position: absolute;
      bottom: -2px;
      left: calc((100% - 140%) / 2);
      z-index: 3;
      width: 140%;
      height: 100px;
      overflow: hidden;

      &:before {
        position: absolute;
        bottom: -336px;
        left: 0;
        width: 100%;
        height: 400px;
        background-color: $background;
        content: '';
        transform: rotate(-3deg);
      }
    }
  }

  &--shaped {
    padding-top: 150px;
    padding-bottom: 150px;

    &:before {
      position: absolute;
      bottom: -336px;
      left: calc((100% - 140%) / 2);
      width: 140%;
      height: 400px;
      background-color: $white;
      content: '';
      transform: rotate(-3deg);
    }

    &:after {
      position: absolute;
      top: -336px;
      left: calc((100% - 140%) / 2);
      width: 140%;
      height: 400px;
      background-color: $background;
      content: '';
      transform: rotate(-3deg);
    }
  }

  @media #{$screen-md} {
    &--shapedBottom .section__container,
    &--shapedTop .section__container,
    &--spaced .section__container {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
