@import '../style/base/colors';
@import '../style/base/variables';
@import '../style/base/layout';

.hero {
  $padding: 3.75rem;

  position: relative;
  max-width: 100%;
  padding-top: $padding;
  overflow: hidden;
  color: $white;
  background: $actionGradinet;

  &__shape {
    position: absolute;
    bottom: -2px;
    left: calc((100% - 140%) / 2);
    width: 140%;
    height: 6.25rem;
    overflow: hidden;

    &:before {
      position: absolute;
      bottom: -21rem;
      left: 0;
      width: 100%;
      height: 25rem;
      background-color: $white;
      content: '';
      transform: rotate(-3deg);
    }
  }

  &__container {
    @extend %container;

    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 6.35rem;
    padding-bottom: 11.25rem;
  }

  &__image {
    position: absolute;
    top: -10rem;
    right: 0;
    z-index: 0;
    width: 25rem;
    opacity: .85;
    transform: translate(30%);
  }

  &__content {
    z-index: 1;
    width: 100%;
  }

  &__title {
    margin: 0;
    margin-bottom: 1em;
    font-size: 2.5rem;
    font-weight: 600;
  }

  &__text {
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: .3px;
    color: $white;

    &:last-child {
      font-weight: 600;
    }

    & + & {
      margin-top: 1em;
    }
  }

  &__description {
    position: relative;
  }

  &__cta {
    align-self: flex-start;
    display: inline-block;
    height: 2.5rem;
    padding: 0 2.5em;
    margin-top: 1.5rem;
    font-size: .8rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: $action;
    text-decoration: none;
    white-space: nowrap;
    background: $lightGradinet;
    border-radius: 5px;
    transition: color .3s ease-in-out;
    transition: background .3s ease-in-out;
    box-shadow: 0 10px 25px 0 rgba($text, .25);
  }

  &__bgShape {
    position: absolute;
    top: 0;
    left: 0;
    width: 50rem;
    transform: translate(-40%, -20%);
  }

  @media #{$screen-sm} {
    &__content {
      max-width: 80%;
    }
  }

  @media #{$screen-md} {
    &__container {
      padding-top: 7.5rem;
      padding-bottom: 12.5rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      max-width: 53%;
    }

    &__image {
      top: 0;
      width: 53rem;
      transform: translate(35%, -30%);
    }

    &__title {
      font-size: 3.4rem;
    }

    &__text {
      padding-right: 3em;
    }

    &__description {
      position: relative;
    }

    &__text + &__text {
      margin-top: 1em;
    }
  }
}
