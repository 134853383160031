@import '../style/base/colors';
@import '../style/base/layout';

.logos {
  margin: 50px 0;

  &__heading {
    margin: 0;
    margin-bottom: 3em;
    font-size: .8rem;
    color: $pale;
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &__image {
    opacity: .8;
  }

  @media #{$screen-md} {
    margin: 5em 0;
  }
}
