@import '../style/base/colors';

.link {
  height: 2.2rem;
  padding: 0 2em;
  font-size: .8rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: $white;
  text-decoration: none;
  white-space: nowrap;
  background: $actionGradinet;
  border-radius: 5px;
  transition: color .3s ease-in-out;
  transition: background .3s ease-in-out;
  box-shadow: 0 10px 25px 0 rgba($rose, .15);

  &__text {
    display: inline-block;
  }

  &:active {
    transform: translateY(3px);
  }

  &--bordered {
    background: transparent;
    border: 2px solid $action;
    box-shadow: none;

    .link__text {
      color: $action;
    }
  }

  &--flat {
    padding: 0;
    background: transparent;
    box-shadow: none;

    .link__text {
      color: $action;
    }
  }
}
