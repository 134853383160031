$screen-sizes: (
  'sm': 30rem,
  'md': 48rem,
  'lg': 73rem,
  'xl': 87rem
);

@function media-min-width($screen-sizes-name) {
  @return ('only screen and (min-width: #{map-get($screen-sizes, $screen-sizes-name)})');
}

@function media-max-width($screen-sizes-name) {
  @return ('only screen and (max-width: #{map-get($screen-sizes, $screen-sizes-name) - .16rem})');
}

$screen-sm: media-min-width('sm');
$screen-md: media-min-width('md');
$screen-lg: media-min-width('lg');
$screen-xl: media-min-width('xl');

$screen-max-sm: media-max-width('sm');
$screen-max-md: media-max-width('md');
$screen-max-lg: media-max-width('lg');

$container-width: map-get($screen-sizes, 'lg');
$container-gutter: 2rem;

%container {
  display: block;
  max-width: $container-width;
  margin: 0 auto;
  padding-right: $container-gutter;
  padding-left: $container-gutter;
}

.container {
  @extend %container;
}
