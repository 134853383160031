@import '../style/base/colors';
@import '../style/base/layout';

.back {
  $size: 40px;

  position: absolute;
  top: $container-gutter;
  left: $container-gutter;
  width: $size;
  height: $size;
  color: $white;
  z-index: 20;
  cursor: pointer;
  transform: scale(.8);
  transition: transform .3s ease, opacity .3s ease;
  opacity: .8;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    width: 20px;
    height: 4px;
    border-radius: 10px;
    background: $white;
    content: '';
  }

  &:before {
    top: calc(100% - 16px);
    transform: rotate(45deg);
  }

  &:after {
    bottom: calc(100% - 16px);
    transform: rotate(-45deg);
  }

  &:hover {
    transform: translateX(-3px) scale(.8);
    opacity: 1;
  }

  @media #{$screen-md} {
    transform: scale(1);

    &:hover {
      transform: translateX(-3px) scale(1);
      opacity: 1;
    }
  }
}
